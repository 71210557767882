import styled from 'styled-components';

import HeroImage from '../../static/images/paris-hero-2.jpg';
import { COLORS } from '../constants.styles';

export const ImageContainer = styled.div`
  background-image: url(${HeroImage});
  background-size: cover;
  height: 650px;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-left: 150px;
  padding-right: 150px;
  @media (max-width: 700px) {
    font-size: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const ReducedHeightImage = styled.div`
  background-image: url(${HeroImage});
  background-size: cover;
  height: 200px;
  background-position: center 55%;
`;
export const ColumnContainer = styled.div`
  margin-bottom: 80px;
`;

export const Header = styled.div`
  color: white;
  font-size: 40px;
  margin-bottom: 10px;
  font-weight: 600;
  @media (max-width: 700px) {
    font-size: 30px;
  }
`;

export const Description = styled.div`
  color: #dddddd;
  max-width: 860px;
  @media (max-width: 700px) {
    font-size: 15px;
  }
`;

export const ContactButton = styled.a`
  color: white !important;
`;

export const Footer = styled.div`
  text-align: center;
  background-color: ${COLORS.OFF_BLACK};
  color: white;
  padding: 10px;
`;

export const CopyrightText = styled.p`
  margin-bottom: 0px;
`;

export const ProductGridWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 30px;
  padding-bottom: 50px;
  @media (max-width: 700px) {
    padding: 0px;
    padding-bottom: 30px;
  }
`;
