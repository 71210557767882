export const COLORS = {
  MAIN_BLUE: '#113293',
  PRIMARY: '#0e5a8a',
  LIGHT_GREY: '#efefef',
  WHITE: '#ffffff',
  OFF_WHITE: '#f2f2f2',
  DARK_GREY: '#797979',
  OFF_BLACK: '#1B2B36',
};

export const COLUMN_ICON_SIZE = 60;
