import React, { Fragment } from 'react';
import { get } from 'lodash';

import Nav from '../components/Nav';

import {
  Header,
  Description,
  ContactButton,
  ImageContainer,
  ReducedHeightImage,
  Footer,
  CopyrightText,
} from '../components/index.styled';

const Page = ({ children, heroBanner }) => {
  return (
    <Fragment>
      <Nav />
      {heroBanner ? (
        <ImageContainer>
          <Header>{get(heroBanner, 'title.text')}</Header>
          <Description
            dangerouslySetInnerHTML={{
              __html: get(heroBanner, 'description.html'),
            }}
          />
          <ContactButton href={get(heroBanner, 'link.text')} className="btn btn-success" role="button">
            {get(heroBanner, 'link_text.text')}
          </ContactButton>
        </ImageContainer>
      ) : (
        <ReducedHeightImage />
      )}
      {children}
      <Footer>
        <CopyrightText>
          {`© ${new Date().getFullYear()} `}
          Grecam. Tous droits réservés.
        </CopyrightText>
      </Footer>
    </Fragment>
  );
};

export default Page;
