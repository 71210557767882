import React from 'react';
import styled from 'styled-components';

import LogoImage from '../../static/images/grecam-logo.png';
import SubLogoImage from '../../static/images/costar-logo.svg';

const NavItem = styled.li`
  margin-right: 30px;
`;

const Logo = styled.img`
  margin: 0;
  margin-right: 15px;
  max-width: none;
  width: 160px;
`;

const SubLogo = styled.img`
  margin-right: 5px;
  width: 90px;
  margin-bottom: 3px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <a className="navbar-brand" href="/">
        <LogoWrapper>
          <Logo src={LogoImage} alt="Grecam logo" />
          <SubLogo src={SubLogoImage} alt="Costar logo" />
        </LogoWrapper>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto align-items-lg-center">
          <NavItem className="nav-item">
            <a className="nav-link" href="/habitat">
              {`Observatoire Immobilier de l'Habitat`}
            </a>
          </NavItem>
          <NavItem className="nav-item">
            <a className="nav-link" href="/entreprise">
              {`Observatoire de l'Immobilier d'Entreprise`}
            </a>
          </NavItem>
          <NavItem className="nav-item">
            <a className="nav-link" href="/etudes-marches">
              Etudes de marché immobilières
            </a>
          </NavItem>
          <NavItem className="nav-item">
            <a className="nav-link" href="/prospective">
              Etudes prospectives immobilières
            </a>
          </NavItem>
          <NavItem className="nav-item">
            <a className="nav-link" href="/qui-sommes-nous">
              Qui sommes nous?
            </a>
          </NavItem>
          <NavItem className="nav-item">
            <a className="nav-link" href="/contact">
              Contact
            </a>
          </NavItem>
        </ul>
        <ul className="navbar-nav ml-auto align-items-lg-center">
          <a href="https://www.grecam.com/oih/login">
            <NavItem className="nav-item">
              <button type="button" className="btn btn-outline-primary">
                Login
              </button>
            </NavItem>
          </a>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
